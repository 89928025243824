<template>
  <div>
    <div class="accordion-container">
      <div class="accordion-item" v-for="(item, i) in equipArray" :key="i">
        <button
          :class="
            isToggle[i]
              ? 'is-clicked accordion acc-title'
              : 'accordion acc-title'
          "
          @click="toggleEquip(i)"
        >
          {{ item.categoryName.toUpperCase() }}
          <Arrow class="arrow" :class="{ clicked: isToggle[i] }" />
        </button>
        <ul v-if="isToggle[i]">
          <li v-for="(equip, j) in item.equipments" :key="j">
            <span> {{ equip }} <Check class="check" /></span>
          </li>
        </ul>
      </div>
    </div>
    <p
      v-if="data.length > 6"
      class="show-more-button align-left"
      @click="isShowMore = !isShowMore"
    >
      <span v-if="!isShowMore">Ver mais equipamentos</span>
      <span v-else>Mostrar menos</span>
    </p>
  </div>
</template>

<script>
import Check from "@/assets/Check.vue";
import Arrow from "@/assets/OpenArrow.vue";

export default {
  name: "EquipList",
  props: {
    data: { type: Array },
  },
  data() {
    return {
      isToggle: [],
      isShowMore: false,
    };
  },
  methods: {
    toggleEquip(i) {
      this.isToggle[i] = !this.isToggle[i];
      this.$forceUpdate();
    },
  },
  computed: {
    equipArray() {
      let arr = [];
      if (this.isShowMore) {
        arr = this.data;
      } else {
        arr = [
          this.data[0],
          this.data[1],
          this.data[2],
          this.data[3],
          this.data[4],
          this.data[5],
        ];
      }
      return arr;
    },
  },
  created() {
    for (let i = 0; i < this.data.length; i++) {
      this.isToggle[i] = false;
    }
  },
  components: {
    Check,
    Arrow,
  },
};
</script>

<style lang="scss">
@import "/Accordion";
</style>
