<template>
  <form
    :action="data.action"
    :id="data.id"
    class="form-ts form-submit"
    method="POST"
  >
    <div class="col-left">
      <template v-for="(field, index) in formData">
        <FormGroup :key="index + '-field'" :data="field" :id="data.id" />
        <div v-if="field.conditional" :key="index + '-conditional'">
          <FormConditionalGroup
            v-if="Object.keys(field.conditional).length > 0"
            :key="index + '-conditional'"
            :data="field.conditional"
            :id="data.id"
          />
        </div>
      </template>
    </div>
    <div class="col-right">
      <template v-for="(field, index) in formData2">
        <FormGroup :key="index + '-field'" :data="field" :id="data.id" />
        <div v-if="field.conditional" :key="index + '-conditional'">
          <FormConditionalGroup
            v-if="Object.keys(field.conditional).length > 0"
            :key="index + '-conditional'"
            :data="field.conditional"
            :id="data.id"
          />
        </div>
      </template>
    </div>
    <div class="col-full">
      <template v-for="(field, index) in formData3">
        <FormGroup
          :key="index + '-field'"
          :data="field"
          :id="data.id"
          :class="field.class"
        />
        <div v-if="field.conditional" :key="index + '-conditional'">
          <FormConditionalGroup
            v-if="Object.keys(field.conditional).length > 0"
            :key="index + '-conditional'"
            :data="field.conditional"
            :id="data.id"
          />
        </div>
      </template>
      <div :id="recaptchaId"></div>
      <span class="form__message">{{ recaptchaError }}</span>
      <ButtonSubmit
        class="mt-2"
        :btnClass="'submitBtn-' + data.id + ' primary-btn '"
        btnLabel="Fazer Marcação"
        :id="data.id"
        @submit="submit"
      />
    </div>
  </form>
</template>

<script>
import ButtonSubmit from "@/components/Buttons/ButtonSubmit.vue";
import FormGroup from "@/components/Forms/FormGroup.vue";
import FormConditionalGroup from "@/components/Forms/FormConditionalGroup.vue";
import { formatPriceNumber } from "@/functions/general.js";

import { submitForm } from "@/functions/forms.js";

export default {
  name: "FormTS",
  props: {
    data: { type: Object },
    image: { type: String },
    car: { type: Object },
    imageUrl: { type: String },
    carType: { type: String, default: "" },
    model: { type: [String, Number], default: "" },
    dealer: { type: [String, Number], default: "" },
  },
  components: {
    ButtonSubmit,
    FormGroup,
    FormConditionalGroup,
  },
  mounted() {
    this.loadCaptcha();
    const separatorIndex = this.data.fields.findIndex(
      (el) => el.name === "separador"
    );
    let separator2Index = this.data.fields.findIndex(
      (el) => el.name === "separador2"
    );
    separator2Index = separator2Index
      ? separator2Index
      : this.data.fields.length;
    this.data.fields.find((el) => el.name === "model").value = this.model;
    if (this.dealer) {
      this.data.fields.find((el) => el.name === "oidDealer").value =
        this.dealer;
    } else {
      this.$store.state.allInstallations.forEach((element) => {
        if (element.designation != undefined || element.objectId != undefined) {
          this.dealers.push({
            label: element.designation + " - " + element.postalCodeDesig,
            value: element.objectId,
          });
        }
      });
      this.data.fields.find((el) => el.name == "oidDealer").options =
        this.dealers;
    }
    this.formData = this.data.fields.slice(0, separatorIndex);
    this.formData2 = this.data.fields.slice(
      separatorIndex + 1,
      separator2Index
    );
    this.formData3 = this.data.fields.slice(separator2Index + 1);
  },
  data() {
    return {
      formData: [],
      formData2: [],
      formData3: [],
      dealers: [],
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      recaptchaId: "recaptcha",
      recaptchaResponse: null,
      recaptchaError: "",
    };
  },
  methods: {
    formatPriceNumber,
    concatAgileLeads(formEl, destination) {
      let mappedFields = [];
      if (destination == "agileleads") {
        mappedFields = [
          "name",
          "phone",
          "email",
          "model",
          "oidDealer",
          "plate",
        ];
      } else {
        mappedFields = ["name", "phone", "email", "oidDealer", "moreinfo"];
        const moreInfo = formEl.querySelector('[name="moreinfo"]');
        moreInfo.value = moreInfo.value == "1" ? "S" : "N";
      }

      const fields = formEl.querySelectorAll(
        "input:not([type='hidden']),select"
      );

      let obs = "";

      fields.forEach((f) => {
        if (f.name == "contactType") {
          if (f.value == "N" || f.value == "E") {
            formEl.querySelector('[name="vehicleType"]').value = "N";
          } else {
            formEl.querySelector('[name="vehicleType"]').value = "U";
          }
        }
        if (!mappedFields.includes(f.name)) {
          if (f.type == "checkbox") {
            if (f.checked) {
              if (f.parentElement.dataset.label) {
                obs += "Label: " + f.parentElement.dataset.label + " - ";
              }
              obs += "Value: " + f.value + " || ";
            }
          } else if (f.type == "radio") {
            if (f.checked) {
              if (f.parentElement.dataset.label) {
                obs += "Label: " + f.parentElement.dataset.label + " - ";
              }
              obs += "Value: " + f.value + " || ";
            }
          } else {
            obs +=
              "Label: " + f.dataset.label + " - Value: " + f.value + " || ";
          }
        }
      });

      obs = obs.replaceAll("(", "/");
      obs = obs.replaceAll(")", "");

      const newInput = document.createElement("input");
      newInput.name = "allFields";
      newInput.type = "hidden";
      newInput.value = obs;
      formEl.append(newInput);
    },
    submit() {
      if (this.recaptchaResponse == null) {
        //alert("Por favor, preencha o reCAPTCHA.");
        this.recaptchaError = "Por favor, preencha o reCAPTCHA.";
        return;
      }
      this.recaptchaError = "";
      const form = document.querySelector("#" + this.data.id);
      const destination = document.querySelector(
        "#" + this.data.id + "-destination"
      );
      if (destination) {
        this.concatAgileLeads(form, destination.value);
      }

      submitForm(this.data.id);
    },

    loadCaptcha() {
      window.onReCaptchaLoad = () => {
        window.grecaptcha.render(this.recaptchaId, {
          sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
          callback: this.onVerify,
          "expired-callback": this.onExpired,
        });
      };

      const recaptchaScript = document.createElement("script");
      recaptchaScript.src =
        "https://www.google.com/recaptcha/api.js?onload=onReCaptchaLoad&render=explicit";
      recaptchaScript.async = true;
      recaptchaScript.defer = true;
      document.head.appendChild(recaptchaScript);
    },
    onVerify(response) {
      this.recaptchaResponse = response;
      // console.log("reCAPTCHA response:", response);
    },
    onExpired() {
      this.recaptchaResponse = null;
      //console.log("reCAPTCHA expired");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Form";
</style>
