<template>
  <div class="simulador row">
    <div :key="updateKey">
      <div
        class="order-label border"
        id="order"
        tabindex="0"
        @focusout="creditToogleState = false"
        @click="creditToogleState = !creditToogleState"
      >
        {{ selectedCredit.creditName }}<Arrow />
        <div
          id="close"
          :class="isClose ? 'option-panel' : 'option-panel close'"
        >
          <div
            class="custom-option"
            v-for="(credit, i) in creditTypes"
            @click="changeCredit(credit)"
            :key="i"
          >
            {{ credit.creditName }}
          </div>
        </div>
      </div>
      <div class="label-container">
        <label>Entrada Inicial</label>
        <label>{{ formatPriceNumber(initEntrance, 2, "€") }}</label>
      </div>
      <InputSlider
        :min="finalPrice * minInitDep"
        :max="finalPrice * maxInitDep"
        :value="initEntrance"
        type="price"
        @onInput="onInput($event, 'initEntrance')"
      />
      <div class="label-container">
        <label>Duração</label>
        <label>{{ monthPeriod }} meses</label>
      </div>
      <InputSlider
        :min="selectedCredit.minNbrPayments"
        :max="selectedCredit.maxNbrPayments"
        step="12"
        :value="monthPeriod"
        type="month"
        class="mb-1"
        @onInput="onInput($event, 'monthPeriod')"
      />
      <ButtonCTA
        btnLabel="A Carregar"
        :btnDisabled="true"
        v-if="isLoading"
        btnClass="disabled-btn "
      />
      <ButtonCTA
        v-else
        btnLabel="Simular Financiamento"
        @action="simulate"
        btnClass="primary-btn "
      />
    </div>

    <div v-if="showValues" class="simulator-values">
      <!-- <List label="Preço Total (PVP + Extras)" :value="simulator.value" /> -->
      <div v-if="showError">
        <p>{{ simulator.message }}</p>
      </div>
      <div v-else>
        <div class="list-price-row">
          <div class="list-price-details" v-if="windowWidth <= 770">
            <div class="range-price-full-pvp">PVP</div>
            <div class="legal-row">
              <div class="list-price-sub">
                {{ formatPriceNumber(finalPrice, 2, "€") }}
              </div>
              <div class="info-legal left mt-0">
                <img :src="require('@/assets/info.svg')" />
                <p class="info-text-right" v-html="legalInfo"></p>
              </div>
            </div>
          </div>
          <div class="list-price-details">
            <div class="legal-row">
              <div class="info-legal">
                <img :src="require('@/assets/info.svg')" />
                <p class="info-text-right" v-html="legalInfo"></p>
              </div>
              <div class="range-price-full-pvp">Em {{ creditName }}</div>
            </div>
            <p class="list-price-sub">
              {{
                formatPriceNumber(
                  simulator.monthlyPayment || simulator.financialInfo.fee,
                  2,
                  "€/mês"
                )
              }}
            </p>
            <p class="list-price-sub">
              TAEG:
              {{
                formatPriceNumberNoSpace(
                  simulator.taeg || simulator.financialInfo.taeg,
                  3,
                  "%"
                )
              }}
            </p>
          </div>
          <div class="list-price-details" v-if="windowWidth > 770">
            <div class="range-price-full-pvp">PVP</div>
            <div class="legal-row">
              <div class="list-price-sub">
                {{
                  formatPriceNumber(
                    finalPrice || simulator.financialInfo.pvp,
                    2,
                    "€"
                  )
                }}
              </div>
              <div class="info-legal left mt-0">
                <img :src="require('@/assets/info.svg')" />
                <p class="info-text-right" v-html="legalInfo"></p>
              </div>
            </div>
          </div>
        </div>
        <div class="list-price-info">
          <p class="list-price-label">
            Entrada inicial:
            <span class="list-price-value">{{
              formatPriceNumber(
                initValues.initEntrance || simulator.financialInfo.initialValue,
                2,
                "€"
              )
            }}</span>
          </p>
          <p class="list-price-label">
            Prazo:
            <span class="list-price-value"
              >{{
                initValues.nrPayments || simulator.financialInfo.nrPayments
              }}
              meses</span
            >
          </p>
          <p class="list-price-label">
            Montante financiado:
            <span class="list-price-value">{{
              formatPriceNumber(
                simulator.financialFee ||
                  simulator.financialInfo.capitalToFinance,
                2,
                "€"
              )
            }}</span>
          </p>
          <p class="list-price-label" v-if="showVFMG && simulator.vfmg">
            VFMG:
            <span class="list-price-value">{{
              formatPriceNumber(simulator.vfmg, 2, "€")
            }}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ButtonCTA from "@/components/Buttons/ButtonCTA.vue";
import Arrow from "@/assets/OpenArrow.vue";
import InputSlider from "@/components/Inputs/InputSlider.vue";

import {
  formatPriceNumber,
  formatPriceNumberNoSpace,
} from "@/functions/general.js";

export default {
  name: "Simulator",
  components: {
    ButtonCTA,
    InputSlider,
    Arrow,
  },
  props: {
    finalPrice: { type: Number, default: 0 },
    vin: { type: String, default: "" },
    initValues: { type: Object, required: false },
    creditTypes: { type: Array },
  },
  data() {
    return {
      initEntrance: 0,
      monthPeriod: 24,
      showValues: true,
      updateKey: 0,
      showError: false,
      creditName: "",
      isLoading: false,
      simulator: this.$store.state.simulator,
      creditToogleState: false,
      windowWidth: window.innerWidth,
      selectedCredit: null,
      showVFMG: false,
    };
  },
  computed: {
    isClose() {
      return this.creditToogleState;
    },
    legalInfo() {
      return this.$store.state.simulator.legalInfo
        ? this.$store.state.simulator.legalInfo
        : "Informação legal sob consulta";
    },
    maxInitDep() {
      if (this.selectedCredit.maxInitialDepositPc) {
        if (this.selectedCredit.maxInitialDepositPc != 0) {
          return this.selectedCredit.maxInitialDepositPc;
        } else {
          return 1;
        }
      } else {
        return 1;
      }
    },
    minInitDep() {
      if (this.selectedCredit.minInitialDepositPc) {
        if (
          this.selectedCredit.minInitialDepositPc != 0 &&
          this.selectedCredit.maxInitialDepositPc != 0
        ) {
          return this.selectedCredit.minInitialDepositPc;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
  },
  created() {
    let unformattedInit = this.initValues.initEntrance.replace("€", "");
    unformattedInit = unformattedInit.replace(",", ".");
    unformattedInit = unformattedInit.replace(/\s/g, "");
    this.initEntrance = Number(unformattedInit);
    this.initValues.initEntrance = Number(unformattedInit);
    let unformattedTaeg = this.initValues.taeg.replace("%", "");
    unformattedTaeg = unformattedTaeg.replace(",", ".");
    unformattedTaeg = unformattedTaeg.replace(/\s/g, "");

    let unformattedCapit = this.initValues.capitalToFinance.replace("€", "");
    unformattedCapit = unformattedCapit.replace(",", ".");
    unformattedCapit = unformattedCapit.replace(/\s/g, "");

    this.monthPeriod = this.initValues.nrPayments;

    this.$store.commit("SIMULATOR", {
      acceptanceFee: 0,
      code: 0,
      financialFee: unformattedCapit,
      idVc: 0,
      isuc: 0,
      kms: this.initValues.kms,
      legalInfo: this.initValues.legalInfo,
      message: "OK",
      monthPeriod: this.initValues.nrPayments,
      monthlyFee: Number(this.initValues.fee),
      monthlyPayment: Number(this.initValues.fee),
      mtic: 0,
      taeg: unformattedTaeg,
      tan: 0,
      vfmg: this.initValues.vfmg
        ? Number(
            this.initValues.vfmg
              .replace(" ", "")
              .replace("€", "")
              .replace(",", ".")
          )
        : 0,
      vin: null,
    });
    this.selectedCredit = this.creditTypes.find(
      (el) => el.produtctId == this.initValues.idFinancialProduct
    );
    this.showVFMG =
      this.creditTypes.find(
        (el) => el.produtctId == this.initValues.idFinancialProduct
      ).produtctId == 1
        ? true
        : false;
    let foundItem =
      this.creditTypes[
        this.creditTypes.findIndex(
          (el) => el.produtctId == this.initValues.idFinancialProduct
        )
      ];
    this.creditTypes.splice(
      this.creditTypes.findIndex(
        (el) => el.produtctId == this.initValues.idFinancialProduct
      ),
      1
    );
    this.creditTypes.unshift(foundItem);
    this.creditName = this.selectedCredit.creditName;
  },
  methods: {
    formatPriceNumberNoSpace,
    formatPriceNumber,
    onInput(event, type) {
      this[type] = event;
    },
    simulate() {
      this.$emit("action");
      this.$store.dispatch("simulateFinance", {
        vin: this.vin,
        monthPeriod: this.monthPeriod,
        finalPrice: this.finalPrice,
        initEntrance: this.initEntrance,
        idProduct: this.selectedCredit.produtctId,
        oidDealerParent: this.$store.state.allInstallations[0].oidParent,
        vmfg: this.simulator.vfmg,
      });
      this.isLoading = true;
    },
    changeCredit(credit) {
      this.selectedCredit = credit;
      if (this.selectedCredit.maxNbrPayments < this.monthPeriod) {
        this.monthPeriod = this.selectedCredit.maxNbrPayments;
      }
      this.creditToogleState = false;
    },
    onResize() {
      this.windowWidth = window.innerWidth;
    },
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  watch: {
    "$store.state.simulator": function () {
      // console.log("simul", this.$store.state.simulator);
      if (
        this.$store.state.simulator.monthlyPayment ||
        this.$store.state.simulator.financialInfo.fee
      ) {
        this.showError = false;
        this.simulator = this.$store.state.simulator;
        // console.log("simul new", this.initValues);
        this.initValues.initEntrance = this.initEntrance;
        this.initValues.nrPayments = this.simulator.monthPeriod;
        this.creditName = this.selectedCredit.creditName;
        if (this.selectedCredit.produtctId != 1) {
          this.showVFMG = false;
        } else {
          this.showVFMG = true;
        }
        this.isLoading = false;
        this.showValues = true;
      } else if (this.$store.state.simulator.code != "0") {
        // console.log(this.$store.state.simulator.message);
        this.showError = true;
        this.isLoading = false;
        this.simulator.message = this.$store.state.simulator.message;
        this.$forceUpdate();
      }
      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Simulator";
</style>
