<template>
  <div>
    <div class="accordion-container">
      <ul>
        <li v-for="(item, i) in data.specifications" :key="i">
          <span v-if="item.description.length > 1"
            >{{ item.title }} - {{ item.description }}</span
          >
          <span v-else-if="item.title == 'Quilómetros'"
            >{{ item.title }} - {{ item.description }} kms</span
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "SpecList",
  props: {
    data: { type: Object },
  },
  data() {
    return {
      isToggle: [],
    };
  },
  methods: {
    toggle(index) {
      this.isToggle[index] = !this.isToggle[index];
      this.$forceUpdate();
    },
  },
  created() {
    for (let i = 0; i < this.data.length; i++) {
      this.isToggle[i] = false;
    }
  },
  components: {},
};
</script>

<style lang="scss">
@import "/Accordion";
</style>
