<template>
  <div class="input-range">
    <input
      type="range"
      :min="min"
      :max="max"
      :value="value"
      :step="step"
      @change="onChange($event)"
      @input="onInput($event)"
    />
  </div>
</template>

<script>
export default {
  name: "InputSlider",
  props: {
    min: {
      type: [Number, String],
      default: 0,
    },
    max: {
      type: [Number, String],
      default: 0,
    },
    value: {
      type: [Number, String],
      default: 0,
    },
    step: {
      type: String,
    },
  },
  methods: {
    // FUNCTION CALLED WHEN VALUE CHANGES ON THE RANGE AFTER MOUSE UP
    onChange(event) {
      this.$emit("onChange", event.target.value);
    },
    // FUNCTION CALLED WHEN VALUE CHANGES ON THE RAGE ON THE FLY
    onInput(event) {
      this.$emit("onInput", event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Inputs";
</style>
