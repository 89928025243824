<template>
  <!-- Additional required wrapper -->
  <!-- If e need navigation buttons -->
  <div class="swiper-container">
    <!-- Additional required wrapper -->
    <div class="swiper-wrapper">
      <!-- Slides -->
      <div class="swiper-slide" v-for="(img, i) in data" :key="i">
        <div :class="isUsed ? 'swiper-inner-used' : 'swiper-inner'">
          <img
            :src="img.url"
            alt="car"
            :id="img.id"
            :class="isUsed ? 'swiper-image-used' : 'swiper-image'"
            @click="select(img)"
          />
        </div>
      </div>
    </div>
    <!-- If we need navigation buttons -->
    <div
      :class="isUsed ? 'swiper-button-prev used' : 'swiper-button-prev'"
    ></div>
    <div
      :class="isUsed ? 'swiper-button-next used' : 'swiper-button-next'"
    ></div>
  </div>
</template>
<script>
import Swiper, { Navigation } from "swiper";
import "swiper/swiper-bundle.css";

Swiper.use([Navigation]);

export default {
  props: {
    data: { type: Array },
    isUsed: { type: Boolean, default: false },
  },
  mounted() {
    new Swiper(".swiper-container", {
      loop: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
      slidesPerView: this.isUsed ? 5 : 4,
      spaceBetween: 1,
      // Responsive breakpoints
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        // when window width is >= 480px
        480: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        // when window width is >= 640px
        640: {
          slidesPerView: 4,
          spaceBetween: 0,
        },
        1200: {
          slidesPerView: 3,
          spaceBetween: 0,
        },
        1400: {
          slidesPerView: 4,
          spaceBetween: 0,
        },
        1600: {
          slidesPerView: this.isUsed ? 5 : 4,
          spaceBetween: 0,
        },
      },
    });
  },
  methods: {
    select(url) {
      this.$emit("alternativeClick", url);
    },
    getImgUrl(url) {
      return require("/src/assets" + url);
    },
  },
};
</script>

<style scoped lang="scss">
@import "/Swiper.scss";
</style>
