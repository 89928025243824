<template>
  <form :id="data.id" class="form-os form-submit">
    <div class="col-left">
      <div class="car-info">
        <!-- <List label="Preço Total (PVP + Extras)" :value="simulator.value" /> -->
        <img v-if="imageUrl" :src="imageUrl" class="car-image" />

        <div class="form-car-info">
          <p class="list-title">
            {{ car.model }} <span>{{ car.desc }}</span>
          </p>
          <div class="list-price-row">
            <div class="list-price-details">
              <div class="legal-row">
                <div class="info-legal">
                  <img :src="require('@/assets/info.svg')" />
                  <p class="info-text" v-html="car.legalInfo"></p>
                </div>
                <p class="list-price-sub">
                  {{ car.fee }}
                </p>
              </div>
              <p class="list-price-sub">TAEG: {{ car.taeg }}</p>
            </div>
            <div class="list-price-details">
              <div class="list-price-pvp">PVP</div>
              <div class="legal-row">
                <div class="info-legal">
                  <img :src="require('@/assets/info.svg')" />
                  <p class="info-text-right" v-html="car.legalInfo"></p>
                </div>
                <div class="list-price-full">
                  {{ car.price }}
                </div>
              </div>
            </div>
          </div>
          <div class="list-price-info">
            <p class="list-price-label">
              Entrada inicial:
              <span class="list-price-value">{{ car.initEntrance }}</span>
            </p>
            <p class="list-price-label">
              Prazo:
              <span class="list-price-value">{{ car.nrPayments }} meses</span>
            </p>
            <p class="list-price-label">
              Montante financiado:
              <span class="list-price-value">{{ car.capitalToFinance }}</span>
            </p>
          </div>
        </div>
      </div>
      <template v-for="(field, index) in formData">
        <FormGroup :key="index + '-field'" :data="field" :id="data.id" />
        <div v-if="field.conditional" :key="index + '-conditional'">
          <FormConditionalGroup
            v-if="Object.keys(field.conditional).length > 0"
            :key="index + '-conditional'"
            :data="field.conditional"
            :id="data.id"
          />
        </div>
      </template>
    </div>
    <div class="col-right">
      <template v-for="(field, index) in formData2">
        <FormGroup
          v-if="field.name != 'seperator'"
          :key="index + '-field'"
          :data="field"
          :id="data.id"
        />
        <div v-if="field.conditional" :key="index + '-conditional'">
          <FormConditionalGroup
            v-if="Object.keys(field.conditional).length > 0"
            :key="index + '-conditional'"
            :data="field.conditional"
            :id="data.id"
          />
        </div>
      </template>
      <div :id="recaptchaId"></div>
      <span class="form__message">{{ recaptchaError }}</span>
      <ButtonSubmit
        :btnClass="'submitBtn-' + data.id + ' primary-btn '"
        btnLabel="Quero Reservar"
        :id="data.id"
        @submit="submit"
      />
    </div>
  </form>
</template>

<script>
import ButtonSubmit from "@/components/Buttons/ButtonSubmit.vue";
import FormGroup from "@/components/Forms/FormGroup.vue";
import FormConditionalGroup from "@/components/Forms/FormConditionalGroup.vue";
import { formatPriceNumber } from "@/functions/general.js";

import { submitForm } from "@/functions/forms.js";

export default {
  name: "FormOS",
  props: {
    data: { type: Object },
    image: { type: String },
    car: { type: Object },
    imageUrl: { type: String },
  },
  components: {
    ButtonSubmit,
    FormGroup,
    FormConditionalGroup,
  },
  created() {
    this.formData = this.data.fields.slice(
      0,
      this.data.fields.findIndex((el) => el.name == "seperator")
    );
    /* this.formData.forEach((el) => {
      if (el.name == "redirok") {
        el.value = window.location.origin + el.value;
      } else if (el.name == "redirnok") {
        el.value = window.location.origin + el.value;
      }
    }); */
    this.formData2 = this.data.fields.slice(
      this.data.fields.findIndex((el) => el.name == "seperator")
    );
    /* this.formData2.forEach((el) => {
      if (el.name == "redirok") {
        el.value = window.location.origin + el.value;
      } else if (el.name == "redirnok") {
        el.value = window.location.origin + el.value;
      }
    }); */
  },
  data() {
    return {
      formData: [],
      formData2: [],
      siteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
      recaptchaId: "recaptcha",
      recaptchaResponse: null,
      recaptchaError: "",
    };
  },
  mounted() {
    this.loadCaptcha();
  },
  methods: {
    formatPriceNumber,
    submit() {
      if (this.recaptchaResponse == null) {
        //alert("Por favor, preencha o reCAPTCHA.");
        this.recaptchaError = "Por favor, preencha o reCAPTCHA.";
        return;
      }
      this.recaptchaError = "";
      const form = document.getElementById("onlineSelling");
      const requiredFields = Array.from(
        form.querySelectorAll(".required:not([type='hidden'])")
      );
      let cp3 = requiredFields
        .find((el) => el.id == "online-selling-postalcode")
        .value.split("-")[1];
      let cp4 = requiredFields
        .find((el) => el.id == "online-selling-postalcode")
        .value.split("-")[0];
      let client = {
        address: requiredFields.find((el) => el.id == "online-selling-address")
          .value,
        cp3: cp3,
        cp4: cp4,
        cpext: requiredFields.find((el) => el.id == "online-selling-cpExt")
          .value,
        email: requiredFields.find((el) => el.id == "online-selling-email")
          .value,
        firstname: requiredFields
          .find((el) => el.id == "online-selling-name")
          .value.split(" ")[0],
        lastname:
          requiredFields
            .find((el) => el.id == "online-selling-name")
            .value.split(" ").length > 1
            ? requiredFields
                .find((el) => el.id == "online-selling-name")
                .value.split(" ")[1]
            : "",
        nif: requiredFields.find((el) => el.id == "online-selling-nif").value,
        phone: requiredFields.find((el) => el.id == "online-selling-phone")
          .value,
      };
      let payMethod = requiredFields.find(
        (el) => el.id == "online-selling-paymentMethod"
      ).value;
      if (submitForm(this.data.id, false, true)) {
        this.$emit("submit", client, payMethod);
      }
    },
    loadCaptcha() {
      window.onReCaptchaLoad = () => {
        window.grecaptcha.render(this.recaptchaId, {
          sitekey: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
          callback: this.onVerify,
          "expired-callback": this.onExpired,
        });
      };

      const recaptchaScript = document.createElement("script");
      recaptchaScript.src =
        "https://www.google.com/recaptcha/api.js?onload=onReCaptchaLoad&render=explicit";
      recaptchaScript.async = true;
      recaptchaScript.defer = true;
      document.head.appendChild(recaptchaScript);
    },
    onVerify(response) {
      this.recaptchaResponse = response;
      //console.log("reCAPTCHA response:", response);
    },
    onExpired() {
      this.recaptchaResponse = null;
      //console.log("reCAPTCHA expired");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "/Form";
</style>
